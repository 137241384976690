
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "widget-4",
  props: {
    widgetClasses: String
  },
  components: {
    Dropdown3
  }
});
